// import "%modules%/header/header";
// import "%modules%/footer/footer";

import "%modules%/materials/materials";
import "%modules%/select2/select2";
import "%modules%/intro-block/intro-block";
import "%modules%/content-table/content-table";
import "%modules%/news-block/news-block";
import "%modules%/form-filter/form-filter";
import "%modules%/product-content/product-content";
import "%modules%/similar-products/similar-products";
import "%modules%/map/map";
import "%modules%/accordion/accordion";
import "%modules%/price/price";
import "%modules%/cards/cards";
import "%modules%/card-org/card-org";
import "%modules%/modal/modal";
import "%modules%/form/form";
// import "%modules%/auth/auth";